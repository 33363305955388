import React from 'react'

import './share-results-explanation.component.scss'


const ShareResultsExplanation = () => {
  return (
    <div className="share-results-ped">
      <div className="share-explanation">
        <h3 className="section-header">Share These Results With a Sleep Specialist</h3>
        <p>
          The ESS-CHAD can provide important information for a sleep specialist in screening for excessive daytime
          sleepiness in narcolepsy. Have your patients and their caregivers share these important scores with a sleep
          specialist and review the diagnostic sleep tests that help identify narcolepsy.
        </p>
      </div>
    </div>
  )
}

export default ShareResultsExplanation
