import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import './submit-button.component.scss'

const SubmitButton = props => {
  const text = props.text || 'SUBMIT INFORMATION'
  const isLoading = props.loading
  const disabled = isLoading ? 'disabled' : ''
  const className = props.className ? props.className : ''
  const hasArrow = props.hasArrow !== undefined ? props.hasArrow : false

  return (
    <button
      onClick={props.onClick}
      className={className + ' submit-button primary-button ' + disabled}
      type="submit"
      disabled={disabled}
    >
      {isLoading && <FontAwesomeIcon className="loading" icon={faSpinner} spin />}
      {text}
      {hasArrow && <FontAwesomeIcon className="arrow-right" icon="angle-right" />}
    </button>
  )
}

export default SubmitButton

SubmitButton.propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hasArrow: PropTypes.bool
}
