import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CheckboxComponent from '@components/checkbox/checkbox.component'
import './ess-screener.component.scss'

class EssScreener extends Component {
  constructor(props) {
    super(props)

    this.handleOptionsChange = this.handleOptionsChange.bind(this)
  }

  handleOptionsChange(changeEvent) {
    this.props.selectResponse(changeEvent)
  }

  render() {
    let essQuestions = [
      {
        label: 'Would Never Fall Asleep',
        number: 0
      },
      {
        label: 'Slight Chance of Falling Asleep',
        number: 1
      },
      {
        label: 'Moderate Chance of Falling Asleep',
        number: 2
      },
      {
        label: 'High Chance of Falling Asleep',
        number: 3
      }
    ]

    return (
      <div className="primary-card wide-card-ped">
        <div className="quiz-header sns-quiz-header">
          <h4>
            Rate your chance of falling asleep <span className="line-break-span">in each activity:</span>
          </h4>
          <div className="response-headers">
            <h5>
              Would Never <br /> Fall Asleep
            </h5>
            <h5>Slight Chance of Falling Asleep</h5>
            <h5>Moderate Chance of Falling Asleep</h5>
            <h5>
              High Chance of <span className="line-break-span"> Falling Asleep </span>
            </h5>
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting and reading</h6>
            <p>1 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map(item => (
              <CheckboxComponent
                key={item.label}
                number={item.number}
                radioGroup="ess1"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting and watching TV or a video</h6>
            <p>2 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map(item => (
              <CheckboxComponent
                key={item.label}
                number={item.number}
                radioGroup="ess2"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting in a classroom at school during the morning</h6>
            <p>3 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map(item => (
              <CheckboxComponent
                key={item.label}
                number={item.number}
                radioGroup="ess3"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting and riding in a car or bus for about half an hour</h6>
            <p>4 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map(item => (
              <CheckboxComponent
                key={item.label}
                number={item.number}
                radioGroup="ess4"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Lying down to rest or nap in the afternoon</h6>
            <p>5 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map(item => (
              <CheckboxComponent
                key={item.label}
                number={item.number}
                radioGroup="ess5"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting and talking to someone</h6>
            <p>6 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map(item => (
              <CheckboxComponent
                key={item.label}
                number={item.number}
                radioGroup="ess6"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting quietly by yourself after lunch</h6>
            <p>7 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map(item => (
              <CheckboxComponent
                key={item.label}
                number={item.number}
                radioGroup="ess7"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting and eating a meal</h6>
            <p>8 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map(item => (
              <CheckboxComponent
                key={item.label}
                number={item.number}
                radioGroup="ess8"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

EssScreener.propTypes = {
  selectResponse: PropTypes.func
}

export default EssScreener
